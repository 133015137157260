import Vue from 'vue'
import App from './App.vue'
Vue.prototype.downloadApp = () => {
  let url = 'https://play.google.com/store/apps/details?id=com.wbvitech.servicash&'
  window.open(url)
}
Vue.config.productionTip = false
import '@/assets/css/app.css'
import router from './router'
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
